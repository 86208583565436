.inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1350px;
  margin: 30px auto;
  @media (max-width: 770px) {
    & > div {
      margin: 25px auto;
    }
  }
}
.timePicker {
  flex: 1;
  margin-top: 10px !important;
}
.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 500px;
  margin: 50px auto;
  button {
    background-color: #bdbdbd;
    color: #3c1053;
    span {
      background-clip: text !important;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(to right, #ad5389, #3c1053);
    }
  }
}

.icon {
  margin-top: 10px;
  margin-left: 2px;
  align-self: center;
  color: #f44336;
  cursor: pointer;
  &:hover {
    color: #b71c1c;
  }
}
.downloadBtn {
  display: flex !important;
  margin: 30px auto !important;
  justify-content: center !important;
  &:hover {
    opacity: 0.85;
  }
}
.calendar {
  width: 315px;
  @media (max-width: 550px) {
    margin-bottom: 35px;
  }
  > div:first-child {
    box-shadow: rgba(0, 0, 0, 0.75) 7px 4px 30px -10px;
    border-radius: 5px;
    > div {
      > div:first-child {
        background: #334d7b;
      }
      > div:nth-child(2) {
        button[class*="MuiPickersDay-daySelected"] {
          background: #334d7b;
        }
      }
    }
  }
}
.calendarDisabled {
  width: 50%;
  @media (max-width: 700px) {
    width: 80%;
    margin-bottom: 20px;
  }
  > div:first-child {
    box-shadow: rgba(0, 0, 0, 0.75) 7px 4px 30px -10px;
    border-radius: 5px;
    > div {
      > div:first-child {
        pointer-events: none;
        background: #334d7b;
      }
      > div:nth-child(2) {
        button[class*="MuiPickersDay-daySelected"] {
          background: #334d7b;
        }
        div[class*="MuiPickersCalendarHeader-switchHeader"] {
          pointer-events: none;
        }
      }
    }
  }
}
