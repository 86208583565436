.bx--header {
  //background-color: #3d4677;
  background: linear-gradient(
    to right,
    #2b5876,
    #4e4376
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-bottom: 0;
  &__menu-trigger:hover {
    background-color: #1e4158;
  }
}

.bx--header__menu-trigger:focus {
  border: 0 !important;
}
.bx--header__menu-trigger:active {
  background-color: #1a2044;
}
.bx--header__menu-item {
  color: #ffffff;
  border: 0 !important;
} /*  color	Gray 30 */

.bx--header__menu-item:hover {
  border: 0 !important;

  background-color: #1e4158 !important;
} /* 	background color	Gray 100-hover*/

.bx--header__menu-item:focus {
  border: 0 !important;
} /* 	border	White*/
.bx--header__menu-item:active {
  background-color: white;
  border: 0 !important;
} /* 	background color	Gray 80*/
.bx--header__submenu-item {
  background-color: #1a2044;
}
.bx--header__menu-title[aria-expanded="true"],
.bx--header__menu-title[aria-expanded="true"] + .bx--header__menu {
  background-color: #1a2044;
}

//CUSTOM SCSS
.Header {
  margin-bottom: 15px;
}
.customDropdown {
  left: unset !important;
  right: 0;
}
.customNav {
  display: block;
  &:before {
    display: none;
  }
}
.bx--side-nav:not(.bx--side-nav--fixed):hover {
}
