.menu {
  display: flex;
}
.logo {
  background-color: #888888;
  align-self: center;
  height: 20px;
  width: 100px;
}
button {
  overflow: auto;
}
.bx--data-table tr {
  height: 3.6rem;
  svg {
    height: 3rem !important;
  }
}

.MuiDialog-root {
  top: 30px !important;
}
