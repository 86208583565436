.customCol {
  margin: 20px 0;
  p {
    margin-bottom: 0;
  }
}
.customCard {
  .ant-col {
    align-self: center;
  }
}
.card {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
  max-width: 300px;
}
