.tg {
  min-width: 250px;
  width: 250px;
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td[class*="vertical"] {
  writing-mode: vertical-rl;
  vertical-align: middle !important;
  width: unset !important;
  p {
    writing-mode: vertical-rl;
    vertical-align: middle !important;
  }
}

.tg td {
  width: 100% !important;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 9px 5px;
  word-break: normal;
  &[class="vertical"] {
    width: unset !important;
  }
}
.tg th {
  width: 100% !important;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-cly1 {
  text-align: left;
  vertical-align: middle;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
