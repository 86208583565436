.root {
  th:last-child {
    border: 1px solid black;
    padding-right: 0px !important;
  }
  td:last-child {
    padding-right: 0px !important;
  }
  th {
    height: 33px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  table {
    max-width: 1545px;
  }
  td {
    width: 52px;
  }
}
.timePicker {
  border: 0;
  div:before {
    border: 0;
  }
  div:after {
    border: 0;
  }
  input {
    padding: 0;
    border: 0;
    text-align: center;
  }
  div {
    div {
      display: none !important;
    }
  }
}
.tableRotate,
.tableCollection {
  border-collapse: collapse;
  td {
    height: 33px;
    border: 1px solid black;
    padding: 6px;
  }
}
.tableCollection {
  td {
    font-size: 14px;
    width: 51px !important;
  }
}
.tableAuto {
  td,
  th {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@-moz-document url-prefix() {
  .mozPad {
    padding-bottom: 4px !important;
  }
  .tableAuto {
    width: auto !important;
  }
}
.muitable-root {
  width: 0;
}
